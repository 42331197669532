<template>
  <div>
    <div v-if="survey!=null">
      <survey :survey="survey" />
    </div>
  </div>
</template>
<script>
import {
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import 'survey-core/defaultV2.css'
import {Survey} from "survey-vue-ui";
import {Model} from "survey-core";

export default {
  components: {
    Survey
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      survey: null,
      view_type: null,
      id: null,
    }
  },
  watch: {
  },
  created() {
    this.view_type = router.currentRoute.query.view_type
    this.id = router.currentRoute.query.id
    useJwt.getSurveyPreview({
      view_type: this.view_type,
      id: this.id,
    })
      .then(response => {
        if (this.view_type=="survey_preview"||this.view_type=="survey_group_preview"){
          const survey_json = response.data.survey_json
          let ss = new Model(survey_json)
          ss.mode = 'display'
          this.survey = ss
        } else if (this.view_type=="survey_answer") {
          const survey_json = response.data.survey_json
          const answer = response.data.answer_json
          const mySurvey = new Model(survey_json)
          mySurvey.data = JSON.parse(answer)
          mySurvey.mode = 'display'
          this.survey = mySurvey
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  mounted() {},
  methods: {
  },
}
</script>
<style>

</style>
